<template>
  <div>
    <a-modal
      width="800px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="套餐详情"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-spin :spinning="spinning">
        <a-row>
          <a-col :span="12">
            <a-descriptions
              title="基本信息"
              :column="1"
              class="iotplt-descriptions iotplt-descriptions-max-6"
            >
              <a-descriptions-item label="套餐名称">
                {{ data.name }}
              </a-descriptions-item>

              <a-descriptions-item label="运营商种类">
                {{ data.carrier_type }}
              </a-descriptions-item>

              <a-descriptions-item label="用户业务模式">
                {{ data.user_business_type }}
              </a-descriptions-item>

              <a-descriptions-item label="套餐周期">
                {{ data.service_cycle }}
              </a-descriptions-item>

              <a-descriptions-item label="套餐容量">
                {{ data.package_capacity }}
              </a-descriptions-item>

              <a-descriptions-item label="语音">
                {{ data.voice_capacity }}
              </a-descriptions-item>

              <a-descriptions-item label="套餐描述">
                {{ data.description }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="12">
            <a-descriptions title="价格设置" :column="2">
              <a-descriptions-item>
                周期数
              </a-descriptions-item>

              <a-descriptions-item>
                套餐价格(元)
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="2"
              v-for="favorablePrice in data.agents_product_favorable_prices"
              :key="favorablePrice.cycles"
            >
              <a-descriptions-item>
                {{ favorablePrice.cycles | formatBigNumber }}
              </a-descriptions-item>

              <a-descriptions-item>
                <span :class="favorablePrice.distributor_price === '待设置' ?'pending-set' : ''">
                  {{ favorablePrice.distributor_price | formatCurrency }}
                </span>
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="2"
              v-if="!isCustomDay"
            >
              <a-descriptions-item>
                超额计费单位
              </a-descriptions-item>

              <a-descriptions-item>
                价格(元)
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.excess_rate_quantity }}
              </a-descriptions-item>

              <a-descriptions-item>
                <span :class="data.excess_distributor_price === '待设置' ? 'pending-set' : ''">
                  {{ data.excess_distributor_price | formatCurrency }}
                </span>
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="1"
              v-if="this.data.user_business_type_slug === 'custom_month' || this.data.user_business_type_slug === 'traffic_share'"
            >
              <a-descriptions-item>
                停机保号价格(元)
              </a-descriptions-item>

              <a-descriptions-item>
                <span :class="data.stop_distributor_price === '待设置' ? 'pending-set' : ''">
                  {{ data.stop_distributor_price | formatCurrency }}
                </span>
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions title="卡片设置" :column="2">
              <a-descriptions-item>
                卡片材质
              </a-descriptions-item>

              <a-descriptions-item>
                卡板价格(元)
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="2"
              v-for="cardType in data.agents_products_card_types"
              :key="cardType.id"
            >
              <a-descriptions-item>
                {{ cardType.card_type_name }}
              </a-descriptions-item>

              <a-descriptions-item>
                <span :class="cardType.price === '待设置' ? 'pending-set' : ''">
                  {{ cardType.price | formatCurrency }}
                </span>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgencyAgentsProductBySelf } from '@/api/agents_product'

export default {
  name: 'ShowAgentsProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      isCustomDay: false,
      spinning: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.spinning = true
      findAgencyAgentsProductBySelf(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          if (this.data.user_business_type_slug === 'custom_day') {
            this.isCustomDay = true
          }
        }

        this.spinning = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.pending-set {
  color: red;
}
</style>
